<template>
	<div class="bg-cover-text overflow-hide">
		<div class="container-lg cng">
			<div class="row no-gutters">
				<div class="col-lg-12">
					<VueSlickCarousel :autoplay="true" :speed="3000" :arrows="false" :dots="true" :pauseOnHover="false" class="cover-slide">
						<div class="slide-item slide-1">
							<div class="slidetext slidetext-1">
								<div class="line1 text-uppercase" v-html="$t('Medical')"></div>
								<div class="line2 text-uppercase" v-html="$t('Expertise Hub')"></div>
								<div class="line3" v-html="$t('Gathering more than 1,000 medical expertises from 22 spacialties to provide a variety of healthcare knowledges')"></div>
								<div class="line4"><a href="https://www.facebook.com/DoctorAtoZ" target="_blank" v-html="$t('CLICK')"></a></div>
							</div>
						</div>
						<div class="slide-item slide-2">
							<div class="slidetext slidetext-2">
								<div class="line1 text-uppercase" v-html="$t('Democratized')"></div>
								<div class="line2 text-uppercase" v-html="$t('Telemedicine')"></div>
								<div class="line3" v-html="$t('Enhance the quality of healthcare by democratized telemedicine and full-service heath solution. Saving time, minimizing (the risk of) errors and improving operation productively')"></div>
								<div class="line4">
									<router-link to="/individual" v-html="$t('CLICK')"></router-link>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</div>

		<u-animate-container class="container pt-5 pb-0 py-md-5">
			<div class="row">
				<div class="col-12 text-center">
					<h1 class="text-bold" v-html="$t('WHY DOCTOR A to Z')"></h1>
				</div>
			</div>
			<div class="row py-4">
				<div class="col-md-6 text-center">
					<u-animate class="img-bd" name="fadeInUp">
						<img src="@/assets/images/img-home1.jpg" class="ir" alt="" />
					</u-animate>
				</div>
				<div class="col-md-6 px-4 mt-4">
					<p class="lg" v-html="$t('why_doctor_detail')"></p>
					<div class="pt-5 pb-0 py-md-5">
						<router-link class="btn-more" to="/about" v-html="$t('READ MORE')"></router-link>
					</div>
				</div>
			</div>
		</u-animate-container>

		<div class="container-fluid py-5 bg-percent">
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<h2><b v-html="$t('WE HELP')"></b></h2>
					</div>
				</div>
				<div class="row py-4">
					<div class="col-md-4 text-center">
						<div class="percentc">
							<div class="percent">
								<div class="text text-uppercase" v-html="$t('Saving more than')"></div>
								<div class="number">
									<ICountUp :delay="1000" :endVal="20000" />
								</div>
								<div class="text text-uppercase" v-html="$t('lives')"></div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center ">
						<div class="percentc">
							<div class="percent">
								<div class="text text-uppercase" v-html="$t('Transforming up to')"></div>
								<div class="number">
									<ICountUp :delay="1000" :endVal="10" />
								</div>
								<div class="text text-uppercase" v-html="$t('healthcare business')"></div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center">
						<div class="percentc">
							<div class="percent">
								<div class="text text-uppercase" v-html="$t('Integrating with over')"></div>
								<div class="number">
									<ICountUp :delay="1000" :endVal="10" />
								</div>
								<div class="text text-uppercase" v-html="$t('partners')"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="row py-4">
					<div class="col-12 text-center">
						<h3 class="text-bold" v-html="$t('With a high security and privacy standard of the medical services')"></h3>
					</div>
					<div class="col-md-10 offset-md-1 text-center">
						<p class="white" v-html="$t('we_help_detail')"></p>
						<a class="btn-action" :href="urlWebTelemed" target="_blank" v-html="$t('Consult a doctor now')"></a>
					</div>
				</div>
			</div>
		</div>

		<div class="container py-5 pt-md-4 pt-4">
			<div class="row">
				<div class="col-md-10 offset-md-1 text-center px-3 my-5">
					<h3 class="blue"><b v-html="$t('SERVICES')"></b></h3>
					<p v-html="$t('We bring up the quality of healthcare service to the high level of standard with a variety of  practical features')"></p>
				</div>
				<div class="col-12 text-center mb-5">
					<u-animate-container class="row">
						<u-animate class="col-sm-6 col-md-3 service" name="flipInY">
							<a class="link-service" :href="urlWebTelemed" target="_blank">
								<div class="icon"><img src="@/assets/images/icon-service1.svg" class="ir" /></div>
								<div class="title"><b v-html="$t('Telemedicine with Doctor')"></b></div>
							</a>
						</u-animate>
						<u-animate class="col-sm-6 col-md-3 service" name="flipInY">
							<a class="link-service" :href="urlWebTelemed" target="_blank">
								<div class="icon"><img src="@/assets/images/icon-service2.svg" class="ir" /></div>
								<div class="title"><b v-html="$t('Drug Delivery')"></b></div>
							</a>
						</u-animate>
						<u-animate class="col-sm-6 col-md-3 service" name="flipInY">
							<a class="link-service" :href="urlWebTelemed" target="_blank">
								<div class="icon"><img src="@/assets/images/icon-service3.svg" class="ir" /></div>
								<div class="title"><b v-html="$t('Second Opinion')"></b></div>
							</a>
						</u-animate>
						<u-animate class="col-sm-6 col-md-3 service" name="flipInY">
							<a class="link-service" target="_blank">
								<div class="icon"><img src="@/assets/images/icon-service4.svg" class="ir" /></div>
								<div class="title"><b v-html="$t('Marketplace')"></b></div>
							</a>
						</u-animate>
					</u-animate-container>

					<div class="mt-5">
						<a class="btn-more" :href="urlWebTelemed" target="_blank" v-html="$t('Consult a doctor now')"></a>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-lightgray">
			<div class="container py-5">
				<div class="row">
					<div class="col-12 text-center">
						<h1 class="text-bold" v-html="$t('OUR SUCCESS PROJECT')"></h1>
					</div>
					<div class="col-12 text-center">
						<p v-html="$t('With a variety of experinces towards reputative companies in the industry')"></p>
					</div>
					<div class="col-12 text-center py-5">
						<VueSlickCarousel :autoplay="true" :speed="1500" :arrows="false" :pauseOnHover="false" :rows="2" :slidesPerRow="5" :responsive="[{ breakpoint: 768 ,settings: { slidesPerRow: 3 } }]" class="success-slide">
							<div class="logos-item my-3"><img src="@/assets/images/logo-success2.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success3.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success4.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success5.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success6.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success10.png" class="ir h96px" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success11.png" class="ir h96px" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success12.png" class="ir h96px" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success7.jpg" class="ir" alt="" /></div>
							<div class="logos-item my-3"><img src="@/assets/images/logo-success9.jpg" class="ir" alt="" /></div>
						</VueSlickCarousel>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-form">
			<div class="container py-0 py-md-5">
				<div class="row">
					<div class="col-lg-6 py-5">
						<form class="apply" @submit.prevent="sendRequestDemo">
							<div class="row">
								<div class="col-12">
									<h1 class="text-bold" v-html="$t('REQUEST A DEMO')"></h1>
								</div>
								<div class="col-12">
									<p v-html="$t('Complete the form below, and we will contact you to schedule a demo within 1-2 business days')"></p>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Company Name')"></label>
									<input type="text" required v-model="model.companyName" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Your Name')"></label>
									<input type="text" required v-model="model.contactName" />
								</div>
								<div class="col-12">
									<label v-html="$t('Industry Type')"></label>
									<select required v-model="model.industryType">
										<option value="" selected disabled v-html="$t('Select')"></option>
										<option value="โรงพยาบาล และคลีนิค" v-html="$t('HOSPITAL & CLINIC SOLUTION')"></option>
										<option value="ธุรกิจภาครัฐ" v-html="$t('SMART CITY SOLUTION')"></option>
										<option value="ธุรกิจประกัน" v-html="$t('INSURANCE SOLUTION')"></option>
										<option value="ธุรกิจบริษัทเอกชน" v-html="$t('EMPLOYEE\'S HEALTH BENEFIT SOLUTION')"></option>
										<option value="ธุรกิจส่งเสริมการท่องเที่ยว" v-html="$t('MEDICAL TOURISM & HOSPITALITY SOLUTION')"></option>
									</select>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Phone')"></label>
									<input type="tel" maxlength="10" required v-model="model.phone" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('E-mail')"></label>
									<input type="email" required v-model="model.email" />
								</div>
								<div class="col-12">
									<label v-html="$t('Message')"></label>
									<textarea class="none-resize" required v-model="model.message"></textarea>
								</div>
								<div class="col-12 text-center text-md-left">
									<input type="submit" class="btn-submit" :value="$t('CONFIRM')" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid cng">
			<VueSlickCarousel :autoplay="true" :speed="1500" :pauseOnHover="false" class="container-fluid cng join-slide">
				<div class="container-fluid cng bg-join bg-join3">
					<div class="container">
						<div class="row">
							<div class="col-12 text-center">
								<h1 class="gold" v-html="$t('JOIN AS AN INVESTOR')"></h1>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 offset-md-1 text-center">
								<div class="join-slide-item">
									<p class="white" v-html="$t('A versatile solution with endless possibilities')"></p>
									<div class="pt-3 pb-5 text-center">
										<router-link class="btn-more" to="/investor" v-html="$t('REGISTER')"></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid cng bg-join bg-join2">
					<div class="container">
						<div class="row">
							<div class="col-12 text-center">
								<h1 class="gold" v-html="$t('JOIN AS A DOCTOR')"></h1>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 offset-md-1 text-center">
								<div class="join-slide-item">
									<p class="white" v-html="$t('Be a part of healthcare community, treating healthcare equally')"></p>
									<div class="pt-3 pb-5 text-center"><a :href="urlRegisterDoctor" target="_blank" class="btn-more" v-html="$t('REGISTER')"></a></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid cng bg-join bg-join1">
					<div class="container">
						<div class="row">
							<div class="col-12 text-center">
								<h1 class="gold" v-html="$t('JOIN AS AN INDIVIDUAL')"></h1>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 offset-md-1 text-center">
								<div class="join-slide-item">
									<p class="white" v-html="$t('Virtual care solution across all patient journey, from the hospital to your home')"></p>
									<div class="pt-3 pb-5 text-center"><a :href="urlRegisterIndividual" target="_blank" class="btn-more" v-html="$t('REGISTER')"></a></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
		<div class="modal modal-open modal-backdrop custom-modal" :class="showModal">
			<div class="modal-dialog modal-xl modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-body">
						<div class="modal-close">
							<button class="btn btn-link close" aria-label="Close" @click="() => showModal = ''">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<img :src="urlPopupBanner" width="100%" style="object-fit: contain;" />
						<div class="line4"><a :href="urlWebTelemed" target="_blank" v-html="$t('Consult a doctor now')"></a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {
		ICountUp,
		VueSlickCarousel
	},
	data: () => ({
		showModal: 'd-flex',
		urlRegisterDoctor: process.env.VUE_APP_URL_REGIS_DOCTOR,
		urlRegisterIndividual: process.env.VUE_APP_URL_REGIS_INDIVIDUAL,
		urlMarketplace: process.env.VUE_APP_URL_MARKETPLACE,
		urlWebTelemed: process.env.VUE_APP_URL_WEB_TELEMED,
		urlPopupBanner: 'https://doctoratoz-static-resources.s3.ap-southeast-1.amazonaws.com/images/Telemedicine-New-Version2024_2.png',
		model: {
			companyName: '',
			contactName: '',
			industryType: '',
			phone: '',
			email: '',
			message: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Request demo from website Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		async sendRequestDemo() {
			let html = ''
			html += '<div>Company Name: ' + this.model.companyName + '</div><br/>';
			html += '<div>Contact Name: ' + this.model.contactName + ' </div><br/>';
			html += '<div>Industry Type: ' + this.model.industryType + ' </div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Message ' + this.model.message + ' </div><br/><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {
				alert(this.$t('Contact information has been sent successfully'));
				this.model = {
					companyName: '',
					contactName: '',
					industryType: '',
					phone: '',
					email: '',
					message: ''
				}
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	},
}
</script>